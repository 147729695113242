import React, { useState } from 'react';
import '../Trips/TripBhutan.css'; // Create a CSS file for styling

function TripBhutan({ tripp }) {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <div className="trip-card">
      <img src={tripp.image} alt={tripp.name} />
      <h2>{tripp.name}</h2>
      <p>{tripp.shortDescription}</p>
      <button className="read-more-btn" onClick={toggleDetails}>
        {showDetails ? 'Hide Details' : 'Read More'}
      </button>
      {showDetails && (
        <div className="trip-details">
          <p>{tripp.longDescription}</p>
          <p>{tripp.firstDescription}</p>
          <p>{tripp.secondDescription}</p>
        </div>
      )}
    </div>
  );
}

export default TripBhutan;
