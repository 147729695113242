import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { FaFacebook, FaGoogle, FaInstagram } from 'react-icons/fa'; // Import Font Awesome icons if needed
import './Navbar.css'; // Create a CSS file for styling
import logo from '../../newlogo.png'


const CustomNavbar = () => {
  return (
    <Navbar expand="lg" className="custom-navbar">
      <Container>
      <div className="brand-wrapper">
      <img src={logo} alt="Logo" className="logo" />
          <div className="brand-text">
          
            <div className="brand-line1">Trip Nepal Trek &</div>
            <div className="brand-line2">Expedition</div>
          </div>
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link  href="/">Home</Nav.Link>
            <Nav.Link href="/">Tours</Nav.Link>
            <Nav.Link href="/">About Us</Nav.Link>
            <Nav.Link href="/">Contact Us</Nav.Link>
            <p></p>
          </Nav>
          <Nav className="social-icons">
            <Nav.Link href="https://www.facebook.com/profile.php?id=100088701302780">
              <FaFacebook className="icon" />
            </Nav.Link>
            <Nav.Link href="https://www.instagram.com">
              <FaGoogle className="icon" />
            </Nav.Link>
          </Nav>
          <p className='infs'>
            <a href="mailto:info@example.com">Tripnepaltrekmyz@gmail.com</a><br />
            <a href="mailto:info@example.com">md@tripnepaltrek.com</a><br />
            <a href="tel:+1234567890">+977 9818119819</a>
          </p>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
