import React, { useState } from 'react';
import '../TripCard/Tripcard.css'; // Create a CSS file for styling

function TripCard({ trip }) {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <div className="trip-card">
      <img src={trip.image} alt={trip.name} />
      <h2>{trip.name}</h2>
      <p>{trip.shortDescription}</p>
      <button className="read-more-btn" onClick={toggleDetails}>
        {showDetails ? 'Hide Details' : 'Read More'}
      </button>
      {showDetails && (
        <div className="trip-details">
          <p>{trip.longDescription}</p>
          <p>{trip.firstDescription}</p>
          <p>{trip.secondDescription}</p>
        </div>
      )}
    </div>
  );
}

export default TripCard;
