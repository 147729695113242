import React from 'react';
import './Footer.css'; // Import the CSS file for styling
import logo from '../../newlogo.png'
import img from '../../contact.jpg'

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-section">
            
            <h4><img src={logo} className='logo' />Trip Nepal Trek & Expedition</h4>
            <p>Serving since 2016. Travel Nepal and Bhutan with us.</p>
          </div>
          <div className="footer-section">
            <h4>Contact Us</h4>
            <img src={img} className='logo' />
            <address>
              <p>
                Address: Chaksibari-Thamel, Kathmandu, Nepal<br />
               
                Decider: Chung Pui Ip<br />
                Email: <a href="mailto:info@example.com">Tripnepaltrekmyz@gmail.com</a><br />
                Email: <a href="mailto:info@example.com">md@tripnepaltrek.com</a><br />
                Phone: <a href="tel:+1234567890">+977 9818119819</a>
              </p>
            </address>
          </div>
        </div>
        <p className="footer-bottom">&copy; Trek Nepal Trip & Expedition. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
