import React from 'react';
import '../about/about.css'; // Create a CSS file for styling
import bhutan from '../../bhutan.jpg'

function AboutPage() {
  return (
    <div className="about-page">
      <h3 className="animated-heading">Know more about Bhutan </h3><hr />
      <div className="content-container">
        <div className="image-container">
          <img src={bhutan} alt="About Us" />
          <div className="text-container">
          <p>
          Bhutan's history spans over 4,000 years, with archaeological findings dating settlements back to 2,000-1,500 BC. 
          Formerly called by various names, it became known as Druk Yul or the Land of Thunder Dragon in the 17th century, 
          while the world called it Bhutan, derived from the Sanskrit word Bhu-Uttan meaning 'High Land.' 
Before Buddhism, Bonism was the dominant religion until the 8th century when Guru Padmasambhava introduced Buddhism.
 Zhabdrung Ngawang Namgyal from Tibet unified Bhutan in 1616 AD, establishing a dual system of governance - Choe Sid (Spiritual and Religious tradition).
  Gongsar Ugyen Wangchuck became the first hereditary monarch in 1907 after a period of civil strife following Zhabdrung's death in 1651.
  The monarchy continued until 2008 when the fourth King introduced democracy, forming a Democratic Constitutional Monarchy. 
  Our curated itinerary covers diverse aspects of Bhutan, including interactions with its people, artists, religious communities,
   festivals, urban and village life, and nature. Expert guides will introduce you to both popular and high cultures.
    Your chosen accommodations and delicious full board meals complement your Bhutanese experience.
          </p>
        </div>
        </div>
        
      </div>
    </div>
  );
}

export default AboutPage;
