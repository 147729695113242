import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import {BrowserRouter,Route,Routes} from 'react-router-dom'
import CustomNavbar from './components/Navbar/Navbar';
import LandingPage from './components/Landing/Landing';
import AnimatedCarousel from './components/Carousel/Carousel';
import Footer from './components/Footer/Footer';
import TripCard from './components/TripCard/Tripcard';
import AboutPage from './components/about/about';
import TripBhutan from './components/Trips/TripBhutan';


import './App.css';

function App() {
  const images = [
    {
      url: './images/abc.PNG',
      name: 'ABC,Nepal',
      description: 'A basecamp near Annapurna Mountain',
    },
    {
      url: './images/boudha.jpg',
      name: 'Boudha,Kathmandu',
      description: 'A holy place showing beauty Boudhist located in Kathmandu,Nepal',
    },
    {
      url: './images/chitwan.png',
      name: 'Chitwan National Park',
      description: 'A famous national park in Nepal',
    },
    
    {
      url: './images/punakha.jpg',
      name: 'Punakha,Bhutan',
      description: 'The palace of great happiness or bliss',
    },
    {
      url: './images/rara.png',
      name: 'Rara,Nepal',
      description: 'Beautiful tourist destination in western nepal',
    },
    {
      url: './images/tigernest.jpg',
      name: 'Tiger Nest,Bhutan',
      description: 'Great architecture in between the hills',
    },
    {
      url: './images/pokhara.png',
      name: 'Pokhara,Nepal',
      description: 'Famous tourist destination in Mid-West Nepal',
    },
    {
      url: './images/durbar.jpg',
      name: 'Kathmandu Durbar Square',
      description: 'Anciently built king houses with ancient styles.',
    },
    {
      url: './images/pasupati.jpg',
      name: 'Pasupatinath,Kathmandu',
      description: 'Most renowned holy place in Nepal where Lord Shiva is worshipped.',
    },
    {
      url: './images/thamel.jpg',
      name: 'Thamel,Kathmandu',
      description: 'Popular for clubs and nightout. Probably Tourism areas',
    },
    {
      url: './images/basantapur.jpg',
      name: 'Basantapur,Kathmandu',
      description: 'Place with collection of ancient old Durbars.',
    },
    {
      url: './images/buddhastatue.jpg',
      name: 'Thimpu,Bhutan',
      description: 'A beautiful statue of Lord Buddha located at top-hill',
    },
    
    // Add more images and descriptions here
  ];

  const trips = [
    {
      name: 'Day 1 - Arrive Thimpu (55km 1.5hours)',

      image: '/images/thimpu.jpg',
      shortDescription: 'Drive to thimpu after immigration formalities.',
      longDescription: `After finishing your immigration formalities, we will drive to Thimphu and visit the following places:\n
`,
      firstDescription : '1. The Memorial Chorten: A huge stupa built in memory of the third King who reigned from 1952-1972.\n',
      secondDescription: '2. Tashichho Dzong: This has been the seat of the government since 1952 and presently houses the throne room and offices of the king, the secretariat, and the ministries of home affairs and finance. Other government departments are housed in buildings nearby.',

    },
    {
      name: 'Day 2 - Thimpu to Punakha (76km 3.5hours)',

      image: '/images/punakha.jpg',
      shortDescription: 'After breakfast crossing the Dochula pass 3100 meters. ',
      longDescription: `After breakfast crossing the Dochula pass 3100 meters. On fine weather you will see the eastern Himalayan ranges including the highest mountain in Bhutan Mt.Gangar Punsum 7520 meters. `,
      firstDescription : `Afternoon we will then hike up to beautiful temple called Khamsum Yuley Namgyal Chorten. It is another 45 minutes uphill walk through the rice fields and the return portion downhill takes around 30 minutes.
      `,
      secondDescription: `Visit the Punakha Dzong, located on the island of the Pho-Chu (male) river and the Mochu (female) river
      `,

    },
    {
      name: 'Day 3 - Punakha to Paro (135km 4.5hours)',

      image: '/images/paro.png',
      shortDescription: 'After breakfast we will be travelling to Paro.. ',
      longDescription: `1. Visit Devine Madman’s Monastery-Chhimi Lhakhang, famously known for its fertility shrine, where couples unable to conceive come here for blessing drive to Thimphu and visit
      `,
      firstDescription : `2.Kuenselphodrang where largest Buddha statue in the country is perched on the hillock overlooking the Thimphu valley and also if interested/if time permits, take a leisure walk through Kuenselphodrang Nature park and enjoy the nice view of the entire Thimphu valley below
      `,
      secondDescription: `On the way back to Paro we will visit Royal Botanical Garden where you can spot all the species of Plants of Bhutan. Flowers and orchids.
      `,

    },
    {
      name: 'Day 4 - Exploring Paro (2 to 3 hours hiking)',

      image: '/images/paroo.png',
      shortDescription: 'Exploring Takstang Monastery..',
      longDescription: `Day hike to the view point of Taktsang monastery, Horses can be arranged with an extra payment. The hike which is all the way uphill takes about 2 /3 hours through villages and pine forests. `,
      firstDescription : `The monastery clings to a huge granite cliff 800 meters from the Paro valley. It is believed that the great saint Padmasambhava came in the 7th century on a flying tigress and meditated in a cave for 3 months. The demons were subdued who were trying to stop the spread of Buddhism and converted the Paro valley into Buddhism. 
      `,
      secondDescription: `During the end of the 17 century a monastery was built on the spot where the saint mediated and it is a pilgrimage site for every Bhutanese to visit once in their life time. 
      `,

    },
    {
      name: 'Day 5 - Departure to Airport',

      image: '/images/airport.png',
      shortDescription: 'Transferring to the airport for departure',
      longDescription: `After breakfast we will transfer you to the airport for your flight.
      `,
      firstDescription : ``,
      secondDescription: ``,

    },
  ]

  const triptoabc = [
    {
      name: 'Day 1 - Kathmandu to Pokhara (30 minutes)',

      image: '/images/1.png',
      shortDescription: 'Depart from Kathmandu to Pokhara (approximately 6-7 hours by road or 30 minutes by flight).',
      longDescription: `Check into Hotel Lakeside Pokhara.
      Explore Pokhara's lakeside area and enjoy a relaxing evening by Phewa Lake.`,
      firstDescription : ``,

    },
    {
      name: 'Day 2 - Pokhara to Ghorepani (2.5 hours)',

      image: '/images/2.png',
      shortDescription: 'Early morning departure from Pokhara to Nayapul by private vehicle.',
      longDescription: `Begin the trek from Nayapul to Ghorepani.
      Overnight stay at Himalaya Guest House, Ghorepani.`,
      firstDescription : ``,

    },
    {
      name: 'Day 3 - Ghorepani to Annapurna Base Camp (4 hours)',

      image: '/images/3.png',
      shortDescription: 'Start early with a hike to Poon Hill for sunrise views.',
      longDescription: `Continue the trek from Ghorepani to Annapurna Base Camp.
      Overnight stay at Machapuchare Base Camp Lodge, Annapurna Base Camp.`,
      firstDescription : ``,

    },
    {
      name: 'Day 4 - ABC to Kathmandu to Pokhara (5.5 hours)',

      image: '/images/4.png',
      shortDescription: 'Wake up early to enjoy sunrise over the Himalayas at Annapurna Base Camp.',
      longDescription: `Descend from Annapurna Base Camp to Nayapul.
      Drive back to Pokhara and check into Hotel Lakeside Pokhara.
      `,
      firstDescription : `Depart from Pokhara to Kathmandu.`,

    },

  ]

  const triptorara = [
    {
      name: 'Day 1 - Kathmandu to Nepalgunj (appx 1hour)',

      image: '/images/1.png',
      shortDescription: 'Board a morning flight from Kathmandu to Nepalgunj,',
      longDescription: `Board a morning flight from Kathmandu to Nepalgunj, a bustling city in the southwestern Terai region of Nepal (approximately 1 hour).
      Upon arrival, transfer to Hotel Siddhartha Nepalgunj, where you'll check in and relax.`,
      firstDescription : `Take a stroll through the local markets of Nepalgunj to experience the vibrant culture and unique atmosphere.`,

    },
    {
      name: 'Day 2 - Nepalgunj to Rara Lake (45 minutes)',

      image: '/images/r2.png',
      shortDescription: 'After an early breakfast, catch a scenic flight from Nepalgunj to Talcha Airport, the gateway to Rara Lake (approximately 45 minutes).',
      longDescription: `Begin your trek from Talcha Airport, venturing through lush forests, charming villages, and picturesque landscapes.
      As you ascend, the air gets crisper and cooler, and you'll arrive at the pristine Rara Lake, nestled in the heart of the Rara National Park.`,
      firstDescription : `Enjoy the tranquility of the lake, its crystal-clear waters reflecting the surrounding snow-capped peaks.
      Overnight stay at a cozy tented camp near Rara Lake, immersing yourself in the serene natural beauty.`,

    },
    {
      name: 'Day 3 - Rara Lake Explore (Whole Day)',

      image: '/images/r3.png',
      shortDescription: 'This day is dedicated to exploring the breathtaking beauty of Rara Lake and its environs.',
      longDescription: `Opt for a peaceful boat ride on the calm waters of the lake or embark on short hikes around the area, offering panoramic views of the landscape.
      Capture the stunning vistas on your camera, and take in the pristine alpine air.
      Marvel at the diverse flora and fauna of Rara National Park, which is home to a variety of wildlife.`,
      firstDescription : `Return to your tented camp for another peaceful night by the lake.`,

    },
    {
      name: 'Day 4 - Talcha Airport to Nepalgunj (2 hours)',

      image: '/images/r4.png',
      shortDescription: 'Bid farewell to Rara Lake as you begin your descent back to Talcha Airport, retracing your steps through the enchanting trail.',
      longDescription: `Once at Talcha Airport, board a flight back to Nepalgunj.
      Reconnect with the comforts of Hotel Siddhartha Nepalgunj, where you can unwind and reminisce about the beauty you've experienced.`,
      firstDescription : ``,

    },
    {
      name: 'Day 5 - Nepalgunj to Kathmandu (1.5 hour)',

      image: '/images/r5.png',
      shortDescription: 'Your adventure concludes with a morning flight from Nepalgunj to Kathmandu.',
      longDescription: `As you soar above the majestic Himalayan peaks, take a moment to reflect on the memories you've created during your journey.
      `,
      firstDescription : `Arrive in Kathmandu, marking the end of your unforgettable trip to Rara Lake.`,

    },
  ]

  // this is for 8 days bhutan bliss
  const tripss = [
    {
      name: 'Day 1 - Fly Paro & Thimpu sightseeing',

      image: '/images/thimpu.jpg',
      shortDescription: 'Transfer to Kathmandu airport in time to board a flight to Bhutan.',
      longDescription: `Transfer to Kathmandu airport in time to board a flight to Bhutan. Upon arrival at Paro, meet
      your guide &amp; vehicle and drive to Thimphu (1.5 Hrs).
`,
      firstDescription : `En-route you will enjoy the Traditional
      Iron Bridge and continue to drive to Thimphu.
      Check-in at hotel and lunch at hotel. In the late afternoon enjoy a sightseeing tour of Buddha
      Statue (Great Buddha Dordenma), Tashichodzong and Thimpu local town.
      Lunch, Dinner &amp; Overnight at Hotel Lundrung, Thimphu`,
      secondDescription: <a href='https://www.ludronghotel.com/'>http://www.ludronghotel.com/</a>,

    },
    {
      name: 'Day 2 - Thimphu sightseeing tour & drive Gangtey/Phobjika Valley',

      image: '/images/day2.png',
      shortDescription: 'After breakfast start your drive to Gangtey crossing the Dochula pass 3100 meters.',
      longDescription: `Continue with an excursion to the Gangtey Gompa valley at an altitude of 2800 meters. The
      only Nyingmapa monastery in western Bhutan is located here. This beautiful valley is also
      home to the rare Black Necked Cranes migrating from the Tibetan plateau to escape the harsh
      winter. `,
      firstDescription : `Currently there are 200 to 300 cranes residing in this valley and the Royal Society for
      Protection of Nature (RSPN) is taking every measure to ensure the safety of the Cranes.
      While the cranes reside in the winter valley for 4/5 months the village folks are not allowed
      to make loud noises or fish in the river that runs through the valley. Explore the fascinating
      glacier valley and return to the Resort.
      Breakfast, Lunch, Dinner & Overnight at Dewachen Hotel, Gangtey -`,
      secondDescription: <a href='https://dewachenhotel.com/'>https://dewachenhotel.com/</a>,

    },
    {
      name: 'Day 3 - Drive Bumthang & sightseeing tour ',

      image: '/images/day3.jpg',
      shortDescription: `Drive almost 6 hours crossing passes over 3000 meters. The route offers magnificent views
      of the Himalayas and beautiful landscape through scattered hamlets and forest of
      rhododendrons and pine. After one and half hours from Chendebji Chorten arrive at a small
      town known as Trongsa meaning new village.`,
      longDescription: `The town mainly consists of fresh Tibetan
      refugees but the most impressive of all is the Trongsa Dzong. The whole of eastern Bhutan
      was controlled from this fortress during the mid 17th century. Proceed further to Bumthang
      visiting the Chume weaving center.
       
      Bumthang, altitude 2600m, has an individuality that charms its visitors and separates it from
      other regions.`,
      firstDescription : `Comprising four smaller valleys, the deeply spiritual region of Bumthang is
      shrouded in religious legend. Here tales of Guru Padmasambhava and his re-incarnation
      known as Tertons still linger in most nooks and corners. It is also known for its woolen
      material (yathra) which can be seen hung outside of houses for sale. Sightseeing – Visit the
      Jakar Dzong, 7th century Jambay Lhakhang, Kurjey monastery, Tamshing and Kunchosum
      monastery
      Breakfast, Lunch, Dinner &amp; Overnight at Dekhyil Home Stay, Bumthang -`,
      secondDescription: <a href='https://dekyilguesthouse.com/'>https://dekyilguesthouse.com/</a>,

    },
    
    {
      name: 'Day 4 - Ura valley excursion with hike',

      image: '/images/day4.png',
      shortDescription: `Excursion with 2 hours hike to Ura village and visit the new Ura monastery.`,
      longDescription: `Excursion with 2 hours hike to Ura village and visit the new Ura monastery. On the way visit
      the Mebartso meaning burning lake.`,
      firstDescription : `Stroll around the village and return back to Guest house.
      Evening walk to the Swiss farm where apple juice, cider, cheese etc are made.
      Breakfast, Lunch, Dinner &amp; Overnight at Dekhyil Home Stay, Bumthang -`,
      secondDescription: <a href='https://dekyilguesthouse.com/'>https://dekyilguesthouse.com/</a>,

    },
    {
      name: 'Day 5 - Drive Punakha. En-route Chimi Lakhang Hike',

      image: '/images/day5.png',
      shortDescription: `Drive to Punakha.`,
      longDescription: `Drive to Punakha. On the way, we stop at Metshina Village and take a 20-minute walk
      through the rice fields to Chimi Lhakhang,`,
      firstDescription : `which is a fertility temple dedicated to the Divine
      Madman or Lama Drukpa Kuenley.
      Breakfast, Lunch, Dinner & Overnight at Four Boutique Hotel, Punakha -`,
      secondDescription: <a href='https://www.fourboutique.com.bt/'>https://www.fourboutique.com.bt/</a>,

    },
    {
      name: 'Day 6 - Punakha Dzong sightseeing. Drive Paro & sightseeing tour',

      image: '/images/day6.png',
      shortDescription: `In the morning, we visit the Punakha Dzong which was built in 1637.`,
      longDescription: `It is also the second
      oldest and the second largest Dzong in Bhutan. Drive to Paro. After lunch, enjoy the fortress
      of Drugyel dzong which still attracts visitors due to the strategic location of the fort. The fort
      defended the Paro valley from the Tibetan invasion from the north in the early 17th century.`,
      firstDescription : `On clear weather Mount Chomolhari 7320 meters can be seen towering over the dzong.
      Proceed through the beautiful valley to the watch tower or locally known as Ta-Dzong. It was
      housed into the National museum in the 1960s by the third King Jigme Dorji Wangchuck.
      The seven floors museum highlights various aspects of Bhutanese culture and history dating
      back to the 7th century. A short walk downhill to the Rinpung Dzong which serves as the
      administrative center and school for monks.
      Breakfast, Lunch, Dinner & Overnight at Gawaling Hotel, Paro`,
      secondDescription: <a href='http://gawalinghotel.com/'>http://gawalinghotel.com/</a>,

    },
    {
      name: 'Day 7 - Takshang monastery hike (Tiger Nest)',

      image: '/images/tigernest.jpg',
      shortDescription: `Morning, hike to Takshang monastery also known as “Tiger Nest”. The hike which is all the
      way uphill takes about 3/4 hours through pine forests (total walk 4/5hrs).`,
      longDescription: `The monastery
      clings to a huge granite cliff 800 meters from the paro valley. It is believed that the great saint
      Padmasambhava came in the 7th century on a flying tigress and meditated in a cave for 3
      months. The demons were subdued who were trying to stop the spread of Buddhism and
      converted the Paro valley into Buddhism.`,
      firstDescription : `During the end of the 17 century a monastery was
      built on the spot where the saint meditated and it is a pilgrimage site for every Bhutanese to
      visit once in their lifetime. On return visit Kichu Lakhang.
      Breakfast, Lunch, Dinner & Overnight at Gawaling Hotel, Paro`,
      secondDescription: <a href='http://gawalinghotel.com/'>http://gawalinghotel.com/</a>,

    },
    {
      name: 'Day 8 - Fly Kathmandu',

      image: '/images/day8.jpg',
      shortDescription: `Transfer to Paro airport in time to board the flight back to Kathmandu. Transfer to your
      booked hotel.`,
      longDescription: `Transfer to Paro airport in time to board the flight back to Kathmandu. Transfer to your
      booked hotel.`,
      firstDescription : ``,
      secondDescription: <a href=''></a>,

    },
  ]
  return (
    <div className="App">

      <CustomNavbar />
      <LandingPage />
      {/* <AboutUs /> */}
      <hr></hr>
      <h2>Know more about Trip Nepal Trek & Expedition</h2>
      <br></br>
      
      {/* here is about us section */}
      <img src='/images/aboutsite.PNG' className='aboutus'/> 
      <hr />
      <h2>Why Trip Nepal Trek & Expedition?</h2>
      <img src='/images/services.PNG' className='services'/>
      <br />
       <hr />
      <h3>Some of our Destination's Sightseeing</h3>
      <AnimatedCarousel images={images} /> 
      
      <br></br>
      <h3 className='head'>Check Some Amazing national & international Trips from us</h3>
      <hr></hr>
      <h3 className='abctrip'>Bhutan Four Nights Five Days Bliss</h3>
      <div className="trip-container">
        {trips.map((trip, index) => (
          <TripCard key={index} trip={trip} />
        ))}
      </div>
      <h2>End of Trip</h2> <hr></hr>

      <h3 className='abctrip'>Annapurna BaseCamp(Nepal) Four Days Trip Package</h3>
      <div className="trip-container">
        {triptoabc.map((trip, index) => (
          <TripCard key={index} trip={trip} />
        ))}
      </div>
      <h2>End of Trip</h2> <hr></hr>

      <h3 className='raratrip'>Rara Lake Tour(Nepal) Five Days Package</h3>
      <div className="trip-container">
        {triptorara.map((trip, index) => (
          <TripCard key={index} trip={trip} />
        ))}
      </div>
      <h2>End of Trip</h2> <hr></hr>

      {/* this is for 8 days bhutan trip bliss */}
      <h2 className='sghts'>See our 8 days Bhutan trip</h2><hr />
      <h4 className='abctrip'>8 Days Bhutan Bliss</h4>
      <div className="trip-container">
        {tripss.map((tripp, index) => (
          <TripBhutan key={index} tripp={tripp} />
        ))}
      </div>
      <br />
      < AboutPage />
          <hr />
      
          <h2>Some Amazing Facts about Bhutan</h2>
          <img src='/images/facts.PNG' className='facts'/>
          <br />
          <hr />
          <h4>Message from Trip Nepal Trek & Expedition</h4>
      <img src='/images/aboutme.PNG' className='services'/>
      <br />

      {/* this is footer */}
      <Footer />


    </div>
  );
}

export default App;
